<template>
  <div class="vx-row w-full">
    <sideview
      :is-sidebar-active="sidebarActivated"
      :data="billing_info"
      @closeSidebar="toggleBillSidebar"
    />
    <div class="vx-row ml-1 mb-base">
      <b-button
        class="primary mb-2"
        variant="primary"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="goBack"
      >Go Back</b-button>
    </div>

    <b-card class="mx-1">
      <b-overlay
        variant="white"
        :show="showLoading"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
      >
        <form-wizard
          id="form-with-loading"
          ref="wizard"
          color="#FF9F43"
          class="mb-3"
          :title="action == 'insert' ? 'Add Job' : 'Edit Job'"
          :subtitle="null"
          :finish-button-text="action == 'insert' ? 'Add Job' : 'Edit Job'"
          @on-complete="onComplete"
          @on-change="formChange"
        >
          <tab-content
            title="Choose The Platform To Post"
            class="mb-2"
            :before-change="handleValidation"
          >
            <div class="flex flex-row-reverse">
            <!-- <vx-tooltip
                class="self-center"
                text="Click here to buy more credits."
                >
                <stripe-checkout
                    ref="checkoutRef"
                    mode="subscription"
                    :pk="publishableKey"
                    :client-reference-id="payment_id"
                    :customer-email="customer_email"
                    :line-items="packageItems"
                    :success-url="successURL"
                    :cancel-url="cancelURL"
                    @loading="(v) => (loading = v)"
                />
                <b-button class="vx-col" variant="dark" @click="subscribe"
                    >Remaining credits : 0</b-button
                >
                </vx-tooltip> -->
            </div>

            <app-collapse
              hover
              type="border"
            >
              <app-collapse-item title="">
                <div slot="header">
                  <h6
                    v-if="
                      number_of_channels[$store.state.auth.ActiveUser.company_name.toLowerCase()]
                    "
                  >
                    Platforms you manage ({{
                      number_of_channels[$store.state.auth.ActiveUser.company_name.toLowerCase()]
                    }})
                  </h6>
                  <h6 v-else>
                    Platforms you manage (0)
                  </h6>
                </div>
                <div
                  v-if="$store.state.auth.ActiveUser.company_name === 'e2i'"
                  class="d-flex mb-2"
                >
                  <b-card
                    border-variant="dark"
                    bg-variant="transparent"
                    class="shadow-none mx-1 p-2"
                  >
                    <b-form-checkbox
                      v-model="optional.destination.pmet"
                    >PMET</b-form-checkbox>
                  </b-card>

                  <b-card
                    border-variant="dark"
                    bg-variant="transparent"
                    class="shadow-none mx-1 p-2"
                  >
                    <b-form-checkbox
                      v-model="optional.destination.non_pmet"
                    >NON-PMET</b-form-checkbox>
                  </b-card>

                  <b-card
                    border-variant="dark"
                    bg-variant="transparent"
                    class="shadow-none mx-1 p-2"
                  >
                    <b-form-checkbox
                      v-model="optional.destination.siso"
                    >SISO WSH</b-form-checkbox>
                  </b-card>
                </div>

                <div
                  v-else-if="
                    $store.state.auth.ActiveUser.company_name === 'PersolKelly'
                  "
                  class="vx-row mx-4"
                >
                  <div class="b-card my-auto flex mt-4 h-24">
                    <b-form-checkbox
                      v-model="optional.destination.persolkelly"
                      class="ml-6"
                    >PersolKelly Singapore</b-form-checkbox>
                  </div>
                </div>

                <div
                  v-else-if="
                    $store.state.auth.ActiveUser.company_name === 'ADECCO PERSONNEL PTE LTD'
                  "
                  class="vx-row mx-4"
                >
                  <div class="b-card my-auto flex mt-4 h-24">
                    <b-form-checkbox
                      v-model="optional.destination.adecco"
                      class="ml-6"
                    >Adecco Job Channel</b-form-checkbox>
                  </div>
                </div>

                <div
                  v-else-if="$store.state.auth.ActiveUser.company_name === 'Steadylah'"
                  class="vx-row mx-4"
                >
                  <div class="b-card my-auto flex mt-4 h-24">
                    <b-form-checkbox
                      v-model="optional.destination.steadylah"
                      class="ml-6"
                    >SteadyLah Network</b-form-checkbox>
                  </div>
                </div>

                <div
                  v-else-if="
                    ['HeadHunters HQ'].includes(
                      $store.state.auth.ActiveUser.company_name,
                    )
                  "
                  class="vx-row mx-4"
                >
                  <div class="b-card my-auto flex mt-4 h-24">
                    <b-form-checkbox
                      v-model="optional.destination.main"
                      class="ml-6"
                    >Main Channel</b-form-checkbox>
                  </div>
                </div>
              </app-collapse-item>
            </app-collapse>

            <hr class="mb-1">

            <app-collapse
              hover
              type="border"
            >
              <app-collapse-item title="">
                <div slot="header">
                  <h6>Other Platforms</h6>
                </div>

                <div class="d-flex flex-wrap justify-items-center" style="gap: 2rem 0;">
                  <b-col
                    v-for="(platform, index) in other_platforms"
                    :key="index"
                    cols="12"
                    md="6"
                    xl="4"
                    style="height: 120px;"
                    class="p-0 p-xl-2 px-md-1"
                  >
                    <b-card
                      style="height: 120px;"
                      border-variant="dark"
                      bg-variant="transparent"
                      class="shadow-none mx-0 mx-xl-1 w-100"
                    >
                      <div class="d-flex flex-column">
                        <div class="d-flex justify-content-between">
                          <div class="">
                            <b-form-checkbox
                              v-if="platform.paid"
                              v-model="optional.destination[platform.destination]"
                              class="w-full"
                              :disabled="
                                credits[platform.destination] > 0 ? false : true
                              "
                            >{{ platform.name }}</b-form-checkbox>
                            <b-form-checkbox
                              v-else
                              v-model="optional.destination[platform.destination]"
                              class="w-full"
                            >{{ platform.name }}</b-form-checkbox>
                          </div>
                          <div class="">
                            <b-badge
                              v-if="platform.paid"
                              pill
                              class="float-right"
                              variant="primary"
                            >Credits: {{ credits[platform.destination] || 0 }}</b-badge>
                            <b-badge
                              v-else
                              pill
                              class="float-right"
                              variant="primary"
                            >Credits:
                              <span>
                                <font-awesome-icon icon="fa-solid fa-infinity" />
                              </span>
                            </b-badge>
                          </div>
                        </div>
                        <hr>
                        <b-row class="w-full my-1">
                          <b-button
                            block
                            :disabled="!platform.paid"
                            variant="primary"
                            class="w-full mx-1"
                            @click="purchaseCredits(platform)"
                          >Purchase Credits</b-button>
                        </b-row>
                      </div>
                    </b-card>

                  </b-col>
                </div>
              </app-collapse-item>
            </app-collapse>

            <div class="vx-row mt-4 h-16 mx-1">
              <b-alert
                show
                variant="warning"
                icon-pack="feather"
                class="p-2"
              >
                <feather-icon
                  variant="warning"
                  icon="InfoIcon"
                  class="mx-2"
                />
                <span>Note: In addition to the platforms selected above, your job
                  will be published into HeadHunters HQ and its network.</span>
              </b-alert>
            </div>
          </tab-content>

          <tab-content
            title="Job Details"
            class="mb-2"
            :before-change="handleValidation"
          >
            <b-row
              v-if="show_bulk"
              class="mt-1"
            >
              <b-col
                cols="12"
                md="3"
                lg="2"
                class="ml-1"
              >
                <h6>Bulk Upload</h6>
                <b-form-checkbox
                  v-model="bulk_upload"
                  name="check-button"
                  switch
                  inline
                  class="mt-1 custom-control-primary"
                />
              </b-col>
              <b-col
                v-if="bulk_upload && action == 'insert'"
                cols="12"
                md="4"
                lg="2"
                class="ml-1 mt-1"
              >
                <b-button
                  variant="warning"
                  type="filled"
                  icon-pack="feather"
                  icon="icon-arrow-down-circle"
                  @click="downloadTemplate"
                >Download Template</b-button>
              </b-col>

              <b-col
                v-if="bulk_upload"
                cols="12"
                md="4"
                lg="2"
                class="ml-1 mt-1"
              >
                <input
                  ref="uploadCSV"
                  class="hidden"
                  type="file"
                  accept=".xls,.xlsx,.xlsm"
                  @change="uploadJobs"
                >

                <b-button
                  id="#button-with-loading"
                  class="w-full shadow-lg"
                  variant="primary"
                  @click="$refs.uploadCSV.click()"
                >Upload File</b-button>
              </b-col>
            </b-row>

            <div
              v-if="false"
              class="vx-row mt-2"
            >
              <div class="vx-col w-full">
                <!-- <b-alert active="{{value.alert}}" variant='danger'> {{value.label}} </b-alert>  -->

                <!-- <b-card>
                <div
                id="button-with-loading"
                class="vs-con-loading__container"
                v-if="image_url == 'default'"
                slot="no-body"
                >
                <img
                    src="@/assets/images/placeholder/profile.svg"
                    alt="content-img"
                    class="responsive card-img-top h-64"
                />
                </div>
                <div
                id="button-with-loading"
                class="vs-con-loading__container"
                v-else
                >
                <img
                    :src="image_url"
                    alt="content-img"
                    class="responsive card-img-top h-64"
                />
                </div> -->
                <!-- <h5 class="mb-2">{{ card_1.title }}</h5>
            <p class="text-grey">{{ card_1.subtitle }}</p>
                <p class="text-grey">{{ card_1.subtitle_2 }}</p>-->
                <!-- <div class="flex justify-between flex-wrap">
                <input
                    type="file"
                    class="hidden"
                    ref="uploadImgInput"
                    @change="updateCurrImg"
                    accept="image/*"
                />

                <b-button
                    class="mt-4 mr-2 shadow-lg w-full"
                    variant="dark"
                    @click="$refs.uploadImgInput.click()"
                    >Upload event image</b-button
                >
                </div>
            </b-card> -->
                <div class="d-flex flex-wrap mt-1">
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <h6 class="">
                      Company Name
                    </h6>
                    <b-form-input
                      v-model="$store.state.auth.ActiveUser.official_company_name"
                      required
                      class="w-full"
                      disabled
                    />

                    <b-row class="mt-1 ">
                      <b-form-checkbox
                        v-if="specialCompanyChecks('A')"
                        v-model="published_fields.recruiter"
                        class="ml-1"
                        disabled
                      >Publish Company Name</b-form-checkbox>
                      <b-form-checkbox
                        v-else
                        v-model="published_fields.recruiter"
                        class="ml-1"
                      >Publish Company Name</b-form-checkbox>
                    </b-row>
                  </b-col>
                  <b-col
                    class="mt-1 mt-md-0"
                    cols="12"
                    md="6"
                  >
                    <h6
                      v-if="
                        $store.state.auth.ActiveUser.company_name === 'PersolKelly' || $store.state.auth.ActiveUser.company_name === 'ADECCO PERSONNEL PTE LTD'
                      "
                      class=""
                    >
                      Job Industry
                    </h6>
                    <h6
                      v-else-if="
                        $store.state.auth.ActiveUser.account_type === this.$UserGroups.AGENCY
                      "
                      class=""
                    >
                      Client Company Name
                    </h6>

                    <v-select
                      v-if="
                        $store.state.auth.ActiveUser.company_name === 'PersolKelly' || $store.state.auth.ActiveUser.company_name === 'ADECCO PERSONNEL PTE LTD'
                      "
                      v-model="select_industry"
                      placeholder="Choose the company industry"
                      multiple
                      :options="categories"
                      label="text"
                      class="selectExample w-full"
                      :reduce="value => value.value"
                      @search="handleInput"
                    />
                    <v-select
                      v-else-if="
                        $store.state.auth.ActiveUser.account_type === this.$UserGroups.AGENCY
                      "
                      v-model="select_company"
                      placeholder="Start typing to get company suggestions"
                      :options="employers"
                      label="text"
                      class="selectExample w-full"
                      :reduce="value => value.value"
                      @search="handleInput"
                    />

                    <div
                      v-if="
                        $store.state.auth.ActiveUser.account_type === this.$UserGroups.AGENCY
                      "
                      class="mt-1"
                    >
                      <b-form-checkbox
                        v-if="specialCompanyChecks('A')"
                        v-model="published_fields.employer"
                        class=""
                        disabled
                      >
                        Publish Client Company Name</b-form-checkbox>
                      <b-form-checkbox
                        v-else
                        v-model="published_fields.employer"
                        class=""
                      >Publish Client Company Name</b-form-checkbox>
                    </div>
                  </b-col>
                </div>

                <div class="d-flex flex-wrap mt-1">
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <h6 class="">
                      Job Owner Email *
                    </h6>
                    <b-form-input
                      v-model="$store.state.auth.ActiveUser.email"
                      required
                      type="email"
                      class="w-full"
                      disabled
                      placeholder="Job owner email"
                    />
                  </b-col>
                  <b-col
                    class="mt-1 mt-md-0"
                    cols="12"
                    md="6"
                  >
                    <h6 class="">
                      Hiring Manager Email
                    </h6>
                    <b-form-input
                      v-model="optional.hiring_manager_email"
                      required
                      type="email"
                      class="w-full"
                      placeholder="Hiring Manager Email"
                    />
                  </b-col>
                </div>

                <div class="mx-1 mt-1">
                  <h6 class="">
                    Position Title
                  </h6>
                  <b-form-input
                    v-model="position"
                    required
                    class="w-full"
                  />
                </div>

                <div class="mx-1 mt-1">
                  <h6
                    v-if="specialCompanyChecks('A')"
                    class=""
                  >
                    Client Profile
                  </h6>
                  <b-form-textarea
                    v-if="specialCompanyChecks('A')"
                    v-model="client_profile"
                    rows="4"
                    class="w-full"
                  />
                </div>

                <div class="mx-1 mt-1">
                  <h6 class="">
                    Number of Vacancies
                  </h6>
                  <b-form-input
                    v-model="optional.vacancies"
                    required
                    onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                    class="w-full"
                    placeholder=""
                  />

                  <div class="vx-col mt-1 ">
                    <b-form-checkbox
                      v-model="published_fields.vacancies"
                    >Publish Number of Vacancies</b-form-checkbox>
                  </div>
                </div>

                <div class="d-flex flex-wrap mt-1">
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <h6 class="">
                      Working Days *
                    </h6>
                    <b-form-input
                      v-model="work_days"
                      required
                      class="w-full"
                      placeholder="For Example, Monday to Friday"
                      :danger="work_days.length > 0 ? false : true"
                      danger-text="You need to add a valid working days."
                      val-icon-danger="icon-x"
                      val-icon-pack="feather"
                    />
                  </b-col>

                  <b-col
                    class="mt-1 mt-md-0"
                    cols="12"
                    md="6"
                  >
                    <h6 class="">
                      Working Hours *
                    </h6>
                    <b-form-input
                      v-model="work_hours"
                      required
                      class="w-full"
                      placeholder="For Example, 9am to 6pm"
                      :danger="work_hours.length > 0 ? false : true"
                      danger-text="You need to add a valid working hours."
                      val-icon-danger="icon-x"
                      val-icon-pack="feather"
                    />
                  </b-col>
                </div>

                <div class="mx-1 mt-1">
                  <h6 class="">
                    Job Type
                  </h6>

                  <div class="row ">
                    <b-form-radio
                      v-model="main_job_types"
                      class="col ml-1"
                      value="parttime"
                    >Part-time</b-form-radio>
                    <b-form-radio
                      v-model="main_job_types"
                      class="col ml-1"
                      value="fulltime"
                    >Full-time</b-form-radio>
                  </div>
                </div>

                <div class="mx-1 mt-1">
                  <h6 class="">
                    Employment Type
                  </h6>
                  <div class="d-flex justify-content-between flex-wrap">
                    <div :class="isMobile ? 'w-100' : 'mb-1'">
                      <b-form-checkbox
                        v-model="job_type.internship_traineeship"
                      >Internship/Traineeship</b-form-checkbox>
                    </div>

                    <div :class="isMobile ? 'w-100' : 'mb-1'">
                      <b-form-checkbox
                        v-model="job_type.freelance"
                      >Freelance</b-form-checkbox>
                    </div>

                    <div :class="isMobile ? 'w-100' : 'mb-1'">
                      <b-form-checkbox
                        v-model="job_type.permanent"
                      >Permanent</b-form-checkbox>
                    </div>

                    <div :class="isMobile ? 'w-100' : 'mb-1'">
                      <b-form-checkbox
                        v-model="job_type.contract"
                      >Contract</b-form-checkbox>
                    </div>

                    <div :class="isMobile ? 'w-100' : 'mb-1'">
                      <b-form-checkbox
                        v-model="job_type.flexiwork"
                      >Flexi-work</b-form-checkbox>
                    </div>

                    <div :class="isMobile ? 'w-100' : 'mb-1'">
                      <b-form-checkbox
                        v-model="job_type.temporary"
                      >Temporary</b-form-checkbox>
                    </div>

                    <div :class="isMobile ? 'w-100' : 'mb-1'">
                      <b-form-checkbox v-model="job_type.prn">
                        PRN
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>

                <div class="d-flex flex-wrap">
                  <b-col
                    class="mt-1"
                    cols="12"
                    md="6"
                  >
                    <h6 class="">
                      Currency
                    </h6>
                    <v-select
                      v-model="salary_currency"
                      placeholder="Choose the salary currency"
                      class="w-full"
                      :options="all_salary_currencies"
                      label="value"
                      :reduce="value => value.value"
                    />
                  </b-col>
                  <b-col
                    class="mt-1"
                    cols="12"
                    md="6"
                  >
                    <h6 class="">
                      Minimum Salary
                    </h6>
                    <b-form-input
                      v-model="min_salary"
                      required
                      class="w-full"
                      type="number"
                      placeholder=""
                      :danger="min_salary > 0 ? false : true"
                      danger-text="You need to add a valid minimum salary."
                      val-icon-danger="icon-x"
                      val-icon-pack="feather"
                    />
                  </b-col>

                  <b-col
                    class="mt-1"
                    cols="12"
                    md="6"
                  >
                    <h6 class="">
                      Maximum Salary
                    </h6>

                    <b-form-input
                      v-model="salary"
                      required
                      class="w-full"
                      type="number"
                      placeholder=""
                      :danger="showSalaryWarning()"
                      :danger-text="
                        parseInt(min_salary) > parseInt(salary)
                          ? 'The minimum salary cannot be greater than the maximum salary'
                          : 'You need to add a valid maximum salary.'
                      "
                      val-icon-danger="icon-x"
                      val-icon-pack="feather"
                    />
                  </b-col>

                  <b-col
                    class="mt-1"
                    cols="12"
                    md="6"
                  >
                    <h6 class="">
                      Salary Payment Cycle
                    </h6>
                    <v-select
                      v-model="salary_cycle"
                      placeholder="Choose the salary cycle"
                      class="w-full"
                      :options="all_salary_cycles"
                      label="value"
                      :reduce="value => value.value"
                    />
                  </b-col>
                </div>

                <div class="mt-2 ml-1">
                  <b-form-checkbox
                    v-model="published_fields.salary"
                  >Publish Salary</b-form-checkbox>
                </div>

                <div class="vx-row w-100">
                  <div class="d-flex flex-wrap justify-content-between mx-1 mt-2">
                    <b-form-radio
                      v-model="work_location_type"
                      class="vx-col w-1/3"
                      value="remote_only"
                    >Remote Only</b-form-radio>
                    <b-form-radio
                      v-model="work_location_type"
                      class="vx-col w-1/3"
                      value="hybrid"
                    >Hybrid (Remote and Office)</b-form-radio>
                    <b-form-radio
                      v-model="work_location_type"
                      class="vx-col w-1/3"
                      value="physical"
                    >Office Only</b-form-radio>
                  </div>

                  <b-row
                    v-if="
                      work_location_type === 'physical' ||
                        work_location_type === 'hybrid'
                    "
                    class="w-100 mx-0"
                  >
                    <b-col
                      class="mt-1"
                      cols="12"
                      md="6"
                    >
                      <h6 class="">
                        Working Location Address
                      </h6>
                      <b-form-input
                        v-model="work_location.address"
                        required
                        class="w-full"
                        :danger="work_location.address.length > 1 ? false : true"
                        danger-text="You need to add a valid address."
                        val-icon-danger="icon-x"
                        val-icon-pack="feather"
                      />
                    </b-col>

                    <b-col
                      class="mt-1"
                      cols="12"
                      md="6"
                    >
                      <h6 class="">
                        Working Location State
                      </h6>
                      <b-form-input
                        v-model="work_location.state"
                        required
                        class="w-full"
                        :danger="work_location.state.length > 1 ? false : true"
                        danger-text="You need to add a valid state."
                        val-icon-danger="icon-x"
                        val-icon-pack="feather"
                      />
                    </b-col>

                    <b-col
                      class="mt-1"
                      cols="12"
                      md="6"
                    >
                      <h6 class="">
                        Working Location Country
                      </h6>

                      <v-select
                        v-model="work_location.country"
                        placeholder="Choose the country where the job is located in"
                        autocomplete
                        class="w-full"
                        :options="allCountries"
                        label="value"
                        :reduce="value => value.value"
                      />
                    </b-col>

                    <b-col
                      class="mt-1"
                      cols="12"
                      md="6"
                    >
                      <h6 class="">
                        Working Location Postal Code
                      </h6>
                      <b-form-input
                        v-model="work_location.postal_code"
                        required
                        class="w-full"
                        :danger="
                          work_location.postal_code.length > 1 ? false : true
                        "
                        danger-text="You need to add a valid postal code."
                        val-icon-danger="icon-x"
                        val-icon-pack="feather"
                      />
                    </b-col>
                  </b-row>
                </div>

                <div class="d-flex flex-wrap">
                  <b-col
                    class="vx-col w-1/2 mt-1"
                    cols="12"
                    md="6"
                  >
                    <h6 class="">
                      Position Level
                    </h6>
                    <v-select
                      v-model="job_level"
                      placeholder="Select the position level"
                      class="w-full"
                      :options="all_position_levels"
                      label="value"
                      :reduce="value => value.value"
                    />
                  </b-col>

                  <b-col
                    class="mt-1"
                    cols="12"
                    md="6"
                  >
                    <h6 class="">
                      Minimum Qualification Level
                    </h6>
                    <v-select
                      v-model="qualification_level"
                      placeholder="Choose the qualification level"
                      class="w-full"
                      :options="all_qualification_levels"
                      label="value"
                      :reduce="value => value.value"
                    />
                  </b-col>
                </div>

                <div class="d-flex flex-wrap">
                  <b-col
                    class="mt-1"
                    cols="12"
                    md="6"
                  >
                    <h6 class="">
                      Minimum Years Of Experience
                    </h6>
                    <b-form-input
                      v-model="min_yoe"
                      required
                      type="number"
                      placeholder="Minimum years of experience required(Only numbers allowed)"
                      class="w-full"
                    />
                  </b-col>

                  <b-col
                    class="mt-1"
                    cols="12"
                    md="6"
                  >
                    <h6 class="">
                      Maximum Years Of Experience (If there is no maximum years of
                      experience, leave it as blank)
                    </h6>
                    <b-form-input
                      v-model="max_yoe"
                      required
                      type="number"
                      placeholder="Maximum years of experience required(Only numbers allowed)"
                      class="w-full"
                    />
                  </b-col>
                </div>

                <div class="mx-1 mt-1">
                  <h6
                    v-if="specialCompanyChecks('A')"
                    class=""
                  >
                    How long should the job last before expiry?
                  </h6>
                  <div
                    v-if="specialCompanyChecks('A')"
                    class="d-flex flex-wrap mt-1"
                  >
                    <div class="vx-col md:w-1/3 w-full mb-0 mb-md-2 mx-1">
                      <b-form-radio
                        v-model="job_expiry"
                        class="w-full"
                        value="7"
                      >1 Week</b-form-radio>
                    </div>

                    <div class="vx-col md:w-1/3 w-full mb-0 mb-md-2 mx-1">
                      <b-form-radio
                        v-model="job_expiry"
                        class="w-full"
                        value="14"
                      >2 Weeks</b-form-radio>
                    </div>

                    <div class="vx-col md:w-1/3 w-full mb-0 mb-md-2 mx-1">
                      <b-form-radio
                        v-model="job_expiry"
                        class="w-full"
                        value="30"
                      >1 Month</b-form-radio>
                    </div>

                    <div class="vx-col md:w-1/3 w-full mb-0 mb-md-2 mx-1">
                      <b-form-radio
                        v-model="job_expiry"
                        class="w-full"
                        value="60"
                      >2 Months</b-form-radio>
                    </div>

                    <div class="vx-col md:w-1/3 w-full mb-0 mb-md-2 mx-1">
                      <b-form-radio
                        v-model="job_expiry"
                        class="w-full"
                        value="90"
                      >3 Months</b-form-radio>
                    </div>
                  </div>
                </div>

                <div class="d-flex flex-wrap w-100">
                  <b-col
                    class="mt-1"
                    cols="12"
                    md="6"
                  >
                    <h6 class="">
                      Job Description *
                    </h6>
                    <b-form-textarea
                      v-model="description"
                      rows="4"
                      class="w-full"
                      :class="description.length > 9 ? '' : 'text-danger'"
                      :label="
                        description.length > 9
                          ? ''
                          : 'The job description needs to have at least 10 characters.'
                      "
                    />
                  </b-col>
                  <b-col
                    class="mt-1"
                    cols="12"
                    md="6"
                  >
                    <h6 class="">
                      Job Requirements *
                    </h6>
                    <b-form-textarea
                      v-model="requirements"
                      rows="4"
                      class="w-full"
                      :class="requirements.length > 0 ? '' : 'text-danger'"
                      :label="
                        requirements.length > 0
                          ? ''
                          : 'The job requirements needs to have at least 10 characters.'
                      "
                    />
                  </b-col>
                </div>

                <div class="mx-1 mt-1">
                  <h6 class="">
                    Job Benefits
                  </h6>
                  <b-form-textarea
                    v-model="job_benefits"
                    rows="4"
                    class="w-full"
                    label="List all the job benefits below"
                  />
                </div>

                <div class="mx-1 mt-1">
                  <h6 class="">
                    Required Skillsets (Press Enter after every skillset)
                  </h6>
                  <v-select
                    v-model="required_skillset"
                    taggable
                    multiple
                    no-drop

                    :options="required_skillset_options"
                  />
                </div>

                <div class="mx-1 mt-1">
                  <h6 class="">
                    Screening Questions(Separate multiple questions by |)
                  </h6>
                  <b-form-input
                    v-model="optional.questions"
                    required
                    class="w-full"
                    placeholder="Separate multiple questions by |"
                  />
                </div>
              </div>
            </div>

            <div
              v-if="!bulk_upload"
              class="vx-row mt-2"
            >
              <div class="vx-col w-full">
                <JobForm
                  :job-id="job_id"
                  :show-interview-input="showInterviewInput"
                  @form-data="updateFormData"
                />
              </div>
            </div>
          <!-- <div v-if="!bulk_upload" class="vx-row mt-8">
            <div class="vx-col w-full">
            <b-button
                v-if="action == 'insert'"
                variant="primary"
                class="mr-3 mb-2 w-full"
                @click="addJob"
                >Add Job</b-button
            >
            <b-button
                v-else
                variant="primary"
                class="mr-3 mb-2 w-full"
                @click="editJob"
                >Edit Job</b-button
            >
            </div>
        </div> -->

          </tab-content>

          <tab-content
            title="Receive More Applicants"
            class="mb-2"
          >
            <div class="d-flex flex-wrap justify-content-between my-1 mx-md-1">
              <b-card
                border-variant="dark"
                bg-variant="transparent"
                class="shadow-none mx-md-1 p-2 w-100"
              >
                <b-form-radio
                  v-model="app_gurantee"
                  class=""
                  value="application"
                ><span> By Applications </span>
                  <b-badge
                    v-b-tooltip.hover.focus.right.v-dark
                    pill
                    variant="warning"
                    title="This Application Generation Method (AGM) charges you a fee for every new application generated. The fee will be charged in equivalent to the match and quality of the applicant."
                  >
                    <span class="font-weight-bolder">?</span>
                  </b-badge>
                </b-form-radio>
                <div
                  v-if="show_options.application"
                  class="mt-1"
                >
                  <b-alert
                    show
                    variant="warning"
                    icon-pack="feather"
                    icon="icon-info"
                    class="p-2"
                  >
                    <span>Tentatively Around S$0.40 - S$3.00 per application.</span>

                  </b-alert></div>
              </b-card>

              <b-card
                border-variant="dark"
                bg-variant="transparent"
                class="shadow-none mx-md-1 p-2 w-100"
              >
                <b-form-radio
                  v-model="app_gurantee"
                  class=""
                  value="guaranteed"
                >
                  <span>By Guaranteed Placements </span>
                  <b-badge
                    v-b-tooltip.hover.focus.right.v-dark
                    pill
                    variant="warning"
                    title="This Application Generation Method (AGM) only charges you a fee after successful placement."
                  >
                    <span class="font-weight-bolder">?</span>
                  </b-badge>

                </b-form-radio>
                <div
                  v-if="show_options.guaranteed"
                  class="mt-1"
                >
                  <div class="">

                    <h6
                      v-if="!guaranteedEstimate()"
                      class="text-warning"
                    >
                      Once you add the minimum salary and maximum salary, you will
                      be able to see the estimates of how much you will pay
                    </h6>
                  <!-- <b-form-input
                        class="w-full ml-6"
                        placeholder="How much are you willing to pay for each placement?"
                        v-model="guaranteed_amount"
                    /> -->
                  </div>

                  <b-row>
                    <b-col
                      v-if="guaranteedEstimate()"
                      cols="6"
                    >
                      <h6>Minimum estimated cost per guaranteed placement (SGD)</h6>
                      <b-form-input
                        v-model="estimated_guaranteed"
                        placeholder=""
                        class=""
                      />
                    </b-col>

                    <b-col
                      cols="6"
                    >
                      <h6>Guaranteed placement period in days</h6>
                      <b-form-input
                        v-model="guaranteed_period"
                        class=""
                        onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                        maxlength="8"
                      />
                    </b-col>
                  </b-row>
                  <div
                    v-if="guaranteed_period > 0"
                    class="mt-1"
                  >
                    <h6>Payment Terms</h6>
                    <b-form-textarea
                      v-model="payment_terms"
                      rows="4"
                      placeholder="Payment Terms"
                      class=""
                    />

                  </div>

                </div>
              </b-card>

              <b-card
                border-variant="dark"
                bg-variant="transparent"
                class="shadow-none mx-md-1 p-2 w-100"
              >
                <b-form-radio
                  v-model="app_gurantee"
                  class=""
                  value="none"
                >None of the above</b-form-radio>
              </b-card>
            </div>
          </tab-content>
        </form-wizard>
      </b-overlay>

      <b-modal
        v-model="add_new_company"
        centered
        hide-footer
        title="Add a new company"
      >
        <div class="sm:vx-row mt-1">
          <h6 class="">
            Company Name
          </h6>
          <b-form-input
            v-model="company_info.name"
            required
            class="w-full"
          />
          <h6 class="mt-1">
            Company UEN NO
          </h6>
          <b-form-input
            v-model="company_info.uen_no"
            required
            class="w-full"
          />
          <h6 class="mt-1">
            Primary SSIC
          </h6>
          <b-form-input
            v-model="company_info.primary_ssic"
            required
            class="w-full"
          />
          <h6 class="mt-1">
            Secondary SSIC
          </h6>
          <b-form-input
            v-model="company_info.secondary_ssic"
            required
            class="w-full"
            placeholder="Leave empty if your company doesnt have a secondary ssic code"
          />
          <b-row class="mx-auto my-2">
            <b-button
              class="mx-auto w-1/2"
              variant="warning"
              @click="createCompany"
            >Add company</b-button>
          </b-row>
        </div>
      </b-modal>

      <b-modal
        v-model="purchase_credits_popup"
        centered
        hide-footer
        title="Purchase Credits"
      >
        <div class="vx-row b-card mt-2 ml-1 p-6">
          <h6 class="">
            How many credits would you want to purchase?
          </h6>

          <b-form-input
            v-model="total_credits"
            class="mx-auto mt-2"
            type="number"
          />

          <div class="my-1 d-flex flex-wrap align-items-center justify-content-between">
            <p class="my-auto">
              Total Amount: <b>SGD {{ total_amount }}</b> 
            </p>

            <div class="">
              <stripe-checkout
                ref="checkoutRef"
                mode="payment"
                :pk="publishableKey"
                :client-reference-id="payment_id"
                :customer-email="customer_email"
                :line-items="packageItems"
                :success-url="successURL"
                :cancel-url="cancelURL"
                @loading="v => (loading = v)"
              />
              <b-button
                class="mx-auto"
                variant="primary"
                @click="payNow"
              >Purchase</b-button>
            </div>
          </div>
        </div>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import { onResizeMixins } from '@/components/mixins/onResizeMixins'
import { FormWizard, TabContent } from 'vue-form-wizard'

import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import { StripeCheckout } from '@vue-stripe/vue-stripe'
import _, { debounce } from 'lodash'
import vSelect from 'vue-select'

import JobForm from '@/components/jobs/JobForm.vue'

import {
  allCountries,
  highestQualifications,
} from '@/constants/datastore'

import {
  BCard,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BModal,
  VBModal,
  BBadge,
  BFormCheckbox,
  BFormRadio,
  BAlert,
  BFormTextarea,
  VBTooltip,
} from 'bootstrap-vue'

import { VueGoodTable } from 'vue-good-table'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Sideview from '../payments/Sideview.vue'

export default {
  components: {
    VueGoodTable,
    BAlert,
    AppCollapse,
    AppCollapseItem,
    BFormTextarea,
    BFormRadio,

    BCard,

    BFormInput,
    BButton,
    BRow,
    BCol,
    BModal,
    BBadge,
    BFormCheckbox,
    Sideview,

    'v-select': vSelect,

    FormWizard,
    TabContent,
    StripeCheckout,
    JobForm,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [
    onResizeMixins,
  ],
  data() {
    return {
      showInterviewInput: false,
      formData: {},
      allCountries: [],
      showLoading: false,
      dashboard_url: 'https://dashboard.headhuntershq.com',
      payment_terms: '',
      action: 'insert',
      bulk_upload: false,
      show_bulk: true,
      main_job_types: '',
      all_position_levels: [
        { text: 'Senior Management', value: 'Senior Management' },
        { text: 'Middle Management', value: 'Middle Management' },
        { text: 'Manager', value: 'Manager' },
        { text: 'Professional', value: 'Professional' },
        { text: 'Senior Executive', value: 'Senior Executive' },
        { text: 'Executive', value: 'Executive' },
        { text: 'Junior Executive', value: 'Junior Executive' },
        { text: 'Non-Executive', value: 'Non-Executive' },
        { text: 'Fresh/Entry Level', value: 'Fresh/Entry Level' },
      ],

      all_portals: [
        'mycareersfuture',
        'jobstreet',
        'jobsdb',
        'glints',
        'workclass',
        'grabjobs',
        'cultjobs',
        'jobs007',
        'pmet',
        'siso',
        'non_pmet',
        'persolkelly',
        'adecco',
      ],
      categories: [
        {
          text: 'Accommodation and food service activities',
          value: 'Accommodation and food service activities',
        },
        {
          text: 'Administrative and support service activities',
          value: 'Administrative and support service activities',
        },
        { text: 'Agriculture and fishing', value: 'Agriculture and fishing' },
        {
          text: 'Arts, entertainment and recreation',
          value: 'Arts, entertainment and recreation',
        },
        { text: 'Construction', value: 'Construction' },
        { text: 'Education', value: 'Education' },
        {
          text: 'Electricity,gas,steam and air-conditioning supply',
          value: 'Electricity,gas,steam and air-conditioning supply',
        },
        {
          text: 'Financial and insurance activities',
          value: 'Financial and insurance activities',
        },
        {
          text: 'Health and social services',
          value: 'Health and social services',
        },
        {
          text: 'Information and communications',
          value: 'Information and communications',
        },
        { text: 'Manufacturing', value: 'Manufacturing' },
        { text: 'Mining and quarrying', value: 'Mining and quarrying' },
        {
          text: 'Other service activities',
          value: 'Other service activities',
        },
        {
          text: 'Professional, scientific and technical activities',
          value: 'Professional, scientific and technical activities',
        },
        {
          text: 'Public administration and defence',
          value: 'Public administration and defence',
        },
        { text: 'Real estate activities', value: 'Real estate activities' },
        {
          text: 'Transportation and storage',
          value: 'Transportation and storage',
        },
        {
          text: 'Water supply;sewerage waste management and remediation activities',
          value:
            'Water supply;sewerage waste management and remediation activities',
        },
        {
          text: 'Wholesale and retail trade',
          value: 'Wholesale and retail trade',
        },
      ],
      other_platforms: [
        {
          name: 'MyCareersFuture',
          destination: 'mycareersfuture',
          paid: false,
        },
        { name: 'JobsDB', destination: 'jobsdb', paid: false },
        { name: 'Glints', destination: 'glints', paid: false },
        { name: 'Jobs007', destination: 'jobs007', paid: false },
        {
          name: 'JobStreet',
          destination: 'jobstreet',
          paid: true,
          credits_price: 185.38,
          test_stripe_price_id: 'price_1KlLQSI2ri5g5XG9Z5Dg7sPt',
          stripe_price_id: 'price_1Klh4hI2ri5g5XG9UnG36W4H',
        },

        {
          name: 'Workclass',
          destination: 'workclass',
          paid: true,
          credits_price: 39.38,
          test_stripe_price_id: 'price_1KlLQvI2ri5g5XG9ppyQ43A9',
          stripe_price_id: 'price_1Klh57I2ri5g5XG9FDFLD9lH',
        },
        // { name: "GrabJobs", destination: "grabjobs", paid: true },
        // { name: "CultJobs", destination: "cultjobs", paid: true },
      ],
      all_salary_cycles: [
        //
        { text: 'Per Annum', value: 'Yearly' },
        { text: 'Per Month', value: 'Monthly' },
        { text: 'Per Week', value: 'Weekly' },
        { text: 'Per Day', value: 'Daily' },
        { text: 'Per Hour', value: 'Hourly' },
      ],
      salary_currency: 'SGD',
      all_salary_currencies: [
        { text: 'SGD', value: 'SGD' },
        { text: 'USD', value: 'USD' },
        { text: 'EUR', value: 'EUR' },
        { text: 'GBP', value: 'GBP' },
      ],
      job_benefits: '',
      required_skillset: [],
      required_skillset_options: [],
      qualification_level: '',
      all_qualification_levels: [],
      credits: {},
      job_expiry: '',
      published_fields: {
        vacancies: true,
        salary: false,
        employer: false,
        recruiter: true,
      },
      job_type: {
        parttime: false,
        freelance: false,
        fulltime: false,
        internship_traineeship: false,
        flexiwork: false,
        permanent: false,
        contract: false,
        temporary: false,
        prn: false,
      },
      number_of_channels: {
        e2i: 3,
        persolkelly: 1,
        steadylah: 1,
        main: 1,
        'adecco personnel pte ltd': 1,
      },
      optional: {
        team: '',
        union: '',
        destination: {
          siso: false,
          pmet: false,
          non_pmet: false,
          steadylah: false,
          persolkelly: false,
          main: false,
          adecco: false,
        },
        vacancies: 1,
        questions: '',
        poster_email: '',
        hiring_manager_email: '',
      },
      guaranteed_amount: '',
      guaranteed_period: 0,
      app_gurantee: 'none',
      app_boost_gurantee: '',
      show_options: {
        application: false,
        guaranteed: false,
        engaging: false,
      },
      employers: [
        { text: 'Start typing to get employer suggestions', value: null },
      ],
      send_where: 'admin_channel',
      position: '',
      description: '',
      requirements: '',
      type_candidate: '',
      work_days: '',
      work_hours: '',
      salary: 0,
      min_salary: 0,
      salary_cycle: '',
      min_yoe: '',
      max_yoe: '',
      job_level: '',
      work_location: {
        country: 'Singapore',
        postal_code: '',
        state: '',
        address: '',
      },
      image_url: '',
      posted_on: '',
      job_id: null,
      source_name: null,
      remote: false,
      client_profile: '',
      selected_job: {},
      select_company: '',
      select_industry: [],
      selected_jobs: [],
      company_info: {},
      add_new_company: false,
      engaged_companies: [],
      all_hiring_agencies: [],
      searchTimer: null,
      purchase_credits_popup: false,
      total_credits: 1,
      minimum_credits: 10,
      maximum_credits: 5000,
      current_credits_price: 0,

      publishableKey: process.env.VUE_APP_STRIPE_KEY,
      packageItems: [
        {
          price: 'price_1Jhl41I2ri5g5XG9J7zkSEga',
          //  price: "price_1IybHQI2ri5g5XG9t1EMtk2H",
          quantity: 1,
        },
      ],
      successURL: `${this.dashboard_url}/payment-success`,
      cancelURL: `${this.dashboard_url}/applicant-tracking/edit-job`,
      payment_id: '',
      sidebarActivated: false,
      proceed_transaction: false,
      billing_info: {},
      customer_email: '',
      current_platform_name: '',
      work_location_type: 'hybrid',
      estimated_guaranteed: null,
    }
  },
  computed: {
    total_amount() {
      return (this.current_credits_price * this.total_credits).toFixed(2)
    },
  },
  watch: {
    select_company(val) {

    },
    app_gurantee(val) {
      if (val === 'guaranteed') {
        this.show_options.guaranteed = true
        this.show_options.application = false
        this.show_options.engaging = false
      } else if (val === 'application') {
        this.show_options.application = true
        this.show_options.guaranteed = false
        this.show_options.engaging = false
      } else if (val === 'engaging') {
        this.show_options.engaging = true
        this.show_options.guaranteed = false
        this.show_options.application = false
      }

      const button = document.querySelector(
        '.wizard-footer-right > span > .wizard-btn',
      )
      if (button) {
        button.innerHTML = 'Next'
      }
    },
    app_boost_gurantee(val) {
      if (val === 'guaranteed') {
        this.show_options.guaranteed = true
        this.show_options.application = false
      } else if (val === 'application') {
        this.show_options.application = true
        this.show_options.guaranteed = false
      }

      const button = document.querySelector(
        '.wizard-footer-right > span > .wizard-btn',
      )
      if (button) {
        button.innerHTML = 'Next'
      }
    },
    main_job_types(val) {
      if (val === 'fulltime') {
        this.job_type.fulltime = true
        this.job_type.parttime = false
      } else {
        this.job_type.fulltime = false
        this.job_type.parttime = true
      }
    },
  },
  mounted() {
    try {
      const next = this.$children[0].$refs.btnclose
      next.$el.addEventListener('click', this.onClickCloseButton, false)
    } catch (error) {}
  },
  created() {
    const { query } = this.$router.currentRoute
    if (query.response === 'cancel') {
      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: 'Credit Purchase Cancelled',
            icon: 'AlertCircleIcon',
            text: 'You have cancelled the purchase credits process',
            variant: 'warning',
          },
        },
        { timeout: this.$longestTimeout },
      ) // this.processCredits(query.platform, query.id);
    }
    this.customer_email = this.$store.state.auth.ActiveUser.email
    this.getCredits()
    this.getHiringAgencies()
    // this.job_id = this.$route.params.job_id
    this.$set(this, 'job_id', this.$route.params.job_id)
    if (this.job_id.includes('_')) {
      this.source_name = this.job_id.split('_')[1]
      this.$set(this, 'job_id', this.job_id.split('_')[0])
    }
    this.generate_payment_id()
    if (this.job_id) {
      this.show_bulk = false
      this.action = 'update'
      const selected = this.$store.state.selected_ats_job
      this.getJob(this.job_id)
    }

    this.all_qualification_levels = _.orderBy(
      highestQualifications,
      ['value'],
      ['asc'],
    )
    this.allCountries = _.orderBy(allCountries, ['value'], ['asc'])
    // this.categories = _.orderBy(allIndustries, ["value"], ["asc"]);
  },
  methods: {
    getCompanyValue() {
      try {
        const company_details = this.select_company.value
        return company_details.split('#')
      } catch (error) {
        const company_details = ' # '
        // this.$toast(
        //   {
        //     component: this.$toastContent,
        //     props: {
        //       title: 'Form Error',
        //       icon: 'AlertCircleIcon',
        //       text: 'Please select the employer company name in the job details form.',
        //       variant: 'danger',
        //     },
        //   },
        //   { timeout: this.$longestTimeout },
        // )
        return company_details.split('#')
      }
    },
    selectHandlers: (map, vm) => ({
      ...map,
      50: e => {
        e.preventDefault()
      },
    }),
    payNow() {
      this.packageItems = [
        {
          price: this.packageItems[0].price,
          quantity: parseInt(this.total_credits),
        },
      ]

      const total_amount = Math.round(
        parseFloat(this.total_credits) * parseFloat(this.current_credits_price),
        2,
      )

      const hidden = window.btoa(
        `${this.current_platform_name}-${this.total_credits}-${total_amount}`,
      )

      // (this.successURL = `${this.dashboard_url}/applicant-tracking/edit-job?response=success&platform=${this.current_platform_name}&id=${this.payment_id}`),
      this.cancelURL = `${this.dashboard_url}/applicant-tracking/edit-job?response=cancel`
      this.successURL = `${this.dashboard_url}/payment-success/${this.payment_id}/500?pq=${hidden}`
      this.$refs.checkoutRef.redirectToCheckout()
    },
    showSalaryWarning() {
      let result = false
      if (this.salary == 0) {
        result = true
      }

      if (parseInt(this.min_salary) > parseInt(this.salary)) {
        result = true
      }
      return result
    },
    toggleBillSidebar() {
      this.sidebarActivated = false
    },
    getBillingInfo(proceed = false, platform = null) {
      this.$http
        .get('/api/billing-info')
        .then(response => {
          if (response.data.success) {
            const results = response.data.output
            this.billing_info = results
            
            const neededKeys = [
              'phone_number',
              'email_address',
              'address_1',
              'first_name',
              'last_name',
              'company_id',
            ]

            if (
              neededKeys.every(key => Object.keys(this.billing_info).includes(key))
            ) {
              this.proceed_transaction = true
            } else {
              this.proceed_transaction = false
            }
            if (proceed) {
              if (this.proceed_transaction) {
                this.purchase_credits_popup = true
                this.current_credits_price = platform.credits_price
                this.current_platform_name = platform.destination
                this.packageItems = [
                  {
                    price: platform.stripe_price_id,
                    quantity: this.total_credits,
                  },
                ]
              } else {
                this.$toastDanger('Billing Notification', 'Please update your billing information before subscribing')
                this.sidebarActivated = true
              }
            }
          } else {
            this.$toastDanger('Billing Notification', 'Please update your billing information before subscribing')
            this.sidebarActivated = true
          }
        })
        .catch(error => {})
    },
    generate_payment_id() {
      this.$http
        .get('/api/payment-id/500')
        .then(response => {
          if (response.data.success) {
            this.payment_id = response.data.payment_id
          }
        })
        .catch(error => {})
    },
    processCredits(platform, payment_id) {
      this.restoreCreds()

      this.$http
        .post('/api/after-payment', {
          data: {
            platform,
            payment_id,
          },
        })
        .then(response => {
          const { success } = response.data
          if (success) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Payment Successful',
                  icon: 'AlertCircleIcon',
                  text: response.data.message,
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )
          } else {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Payment Failed',
                  icon: 'AlertCircleIcon',
                  text: response.data.message,
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }
        })
        .catch(error => {})
    },
    purchaseCredits(platform) {
      this.getBillingInfo(true, platform)
    },
    onComplete() {
      if (this.action == 'insert') {
        this.addJob()
      } else {
        this.editJob()
      }
    },
    onClickCloseButton() {
      this.current_credits_price = 0
      this.$emit('closePopup')
    },
    getJob(job_id) {
      this.showLoading = true
      this.restoreCreds()
      this.$http
        .get(`/api/ats-job/${job_id}`)
        .then(response => {
          if (response.data.success) {
            const job = response.data.details
            this.selected_job = job
            this.position = job.position
            this.description = job.description
            this.requirements = job.requirements
            this.work_hours = job.work_time
            this.work_days = job.work_days
            this.min_salary = job.min_salary
            this.salary = job.salary
            this.salary_cycle = job.salary_cycle
            this.remote = job.remote

            this.optional.hiring_manager_email = job.hiring_manager_email
            this.optional.poster_email = job.poster_email
            this.optional.vacancies = job.vacancies
            this.work_location = job.location
            this.qualification_level = job.qualification_level
            // this.published_fields = job.published_fields
            this.work_location_type = job.work_location_type
            this.min_yoe = job.min_yoe
            this.max_yoe = job.max_yoe
            this.job_level = job.job_level
            this.salary_currency = job.salary_currency
            this.job_benefits = job.job_benefits

            this.required_skillset = job.required_skillset
            this.select_company = job.employer
            this.client_profile = job.client_profile
            this.optional.questions = job.questions

            const published = ['salary', 'employer', 'vacancies', 'recruiter'].map(item => {
              if (job.published.includes(item)) {
                this.published_fields[item] = true
              }
            })

            const postChannels = ['pmet', 'siso', 'non_pmet', 'recruiter',
              'steadylah', 'persolkelly', 'main', 'adecco'].map(item => {
              if (job.post_channel.includes(item)) {
                this.optional.destination[item] = true
              }
            })

            this.other_platforms.map(item => {
              const { destination } = item

              if (job.post_channel.includes(destination)) {
                this.optional.destination[destination] = true
              }
            })

            this.main_job_types = job.type
            this.vacancies = job.vacancies

            this.app_gurantee = job.agm_type

            this.engaged_companies = job.engaged_companies

            this.guaranteed_period = job.agm_details
              ? job.agm_details.guaranteed_period
              : 0
            this.estimated_guaranteed = job.agm_details
              ? job.agm_details.estimated_guaranteed
              : 0
            this.payment_terms = job.agm_details
              ? job.agm_details.payment_terms
              : ''

            if (job.preferences) {
              const { preferences } = job

              if (preferences.includes('internship_traineeship')) {
                this.job_type.internship_traineeship = true
              }
              if (preferences.includes('freelance')) {
                this.job_type.freelance = true
              }
              if (preferences.includes('contract')) {
                this.job_type.contract = true
              }
              if (preferences.includes('permanent')) {
                this.job_type.permanent = true
              }
              if (preferences.includes('temporary')) {
                this.job_type.temporary = true
              }
              if (preferences.includes('flexiwork')) {
                this.job_type.flexiwork = true
              }
              if (preferences.includes('prn')) {
                this.job_type.prn = true
              }
            }
            this.showLoading = false
          }
        })
        .catch(error => {
          this.$handleErrorResponse(error)
          this.showLoading = false
        })
    },
    createCompany() {
      this.restoreCreds()
      const { company_info } = this
      this.$http
        .post('/api/create-company', { data: company_info })
        .then(response => {
          const { success } = response.data
          if (success) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Company Creation',
                  icon: 'AlertCircleIcon',
                  text: response.data.message,
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )
            this.add_new_company = false
            this.confirmUpload()
            this.$refs.uploadCSV.value = ''
            this.file_upload = ''
          } else {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Company Creation',
                  icon: 'AlertCircleIcon',
                  text: response.data.message,
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }
        })
        .catch(error => {})
    },
    guaranteedEstimate() {
      const output = null

      const max_salary = parseInt(this.salary)
      const min_salary = parseInt(this.min_salary)
      if (max_salary > 0 && min_salary) {
        const estimate = Math.round(((max_salary + min_salary) / 2) * 0.15)
        this.estimated_guaranteed = estimate
        return `Estimated cost would be S$${estimate} per guaranteed placement.`
      }
      return output
    },
    addJob() {
      if (this.bulk_upload) {
        this.$bvModal
          .msgBoxConfirm(
            'You are about to upload multiple jobs. Do you want to proceed?',
            {
              title: 'Confirm File Upload',
              size: 'sm',
              okVariant: 'warning',
              okTitle: 'Yes',
              cancelTitle: 'Cancel',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            },
          )
          .then(value => {
            if (value) {
              this.confirmUpload()
            } else {
              this.cancelUpload()
            }
          })
      } else if (
        this.formData.position
        && this.formData.description
        && this.formData.requirements
        && this.formData.working_days
        && this.formData.working_time
        && this.formData.min_salary
        && this.formData.max_salary
        // && this.select_company
      ) {
        const all_data = this.extract_values()
        this.restoreCreds()

        if (all_data) {
          this.$http
            .post('/api/job/ats', all_data)
            .then(response => {
              const { success } = response.data
              if (success) {
                for (const portal of this.all_portals) {
                  if (this.optional.destination[portal]) {
                    this.credits[portal] -= 1
                  }
                }

                this.$router.push('/applicant-tracking')

                this.$toast(
                  {
                    component: this.$toastContent,
                    props: {
                      title: 'Job Manager',
                      icon: 'AlertCircleIcon',
                      text: response.data.message,
                      variant: 'success',
                    },
                  },
                  { timeout: this.$longestTimeout },
                )
              } else {
                this.$toast(
                  {
                    component: this.$toastContent,
                    props: {
                      title: 'Job Manager',
                      icon: 'AlertCircleIcon',
                      text: response.data.message,
                      variant: 'danger',
                    },
                  },
                  { timeout: this.$longestTimeout },
                )
              }

              // this.reset_form();
              this.popupActive = false
            })
            .catch(error => {})
        // this.reset_form();
        }
      } else {
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'Form Error',
              icon: 'AlertCircleIcon',
              text: 'Please fill in all the required job details',
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },
    editJob() {
      this.restoreCreds()

      const all_data = this.extract_values()

      const update_fields = {}

      for (const [key, value] of Object.entries(all_data)) {
        let equiv_key = ''
        if (key == 'work_hours') {
          equiv_key = 'work_time'
        } else if (key == 'work_location') {
          equiv_key = 'location'
        } else if (key == 'target_channel') {
          equiv_key = 'post_channel'
        } else {
          equiv_key = key
        }
        if (this.selected_job[equiv_key] != all_data[key]) {
          if (!['published', 'remote'].includes(key)) {
            // console.log(key, value);
            update_fields[key] = value
          }
        } else {
          const fields = ['work_location', 'questions']
          if (fields.includes(key)) {
            update_fields[key] = value
          }
        }
      }

      const update_array = [this.job_id]

      // for (let item of this.selected_jobs) {
      //   update_array.push(item["_id"]);
      // }
      if (update_fields === undefined || update_fields.length === 0) {
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'Edit Notification',
              icon: 'AlertCircleIcon',
              text: 'No changes detected',
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      } else {
        const company = this.$store.state.auth.ActiveUser.company_name
        this.restoreCreds()
        
        this.$http
          .put(`/api/ats-jobs/${company}`, {
            job_ids: update_array,
            update_values: all_data,
            update_type: 'update',
          })
          .then(response => {
            const cleaner = response.data

            if (cleaner.success) {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Operation Update',
                    icon: 'AlertCircleIcon',
                    text: 'You have edited the job(s) successfully',
                    variant: 'success',
                  },
                },
                { timeout: this.$longestTimeout },
              )

              this.$router.push('/applicant-tracking')
            }
          })
          .catch(error => {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Operation Failed',
                  icon: 'AlertCircleIcon',
                  text: 'Please try again later!',
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
          })
      }
    },

    confirmUpload() {
      const input = this.file_upload
      const { email } = this.$store.state.auth.ActiveUser
      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: 'File Upload Status',
            icon: 'AlertCircleIcon',
            text: 'File upload in progress.',
            variant: 'warning',
          },
        },
        { timeout: this.$longestTimeout },
      )
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {}
        const fd = new FormData()
        const all_data = this.extract_values()
        fd.append('target_channels', all_data.target_channel)
        fd.append('file', input.target.files[0])
        fd.append('agm', this.app_gurantee !== 'none')
        fd.append('agm_type', this.app_gurantee)
        fd.append('engaged_companies', this.engaged_companies)
        fd.append('guaranteed_period', this.guaranteed_period)
        fd.append('estimated_guaranteed', this.estimated_guaranteed)
        fd.append('payment_terms', this.payment_terms)

        delete this.$http.defaults.headers.common.Authorization
        this.$http
          .post(`/api/upload-csv/${email}`, fd)
          .then(response => {
            if (response.data.errors.length > 0) {
              this.popupActive = false
              if (response.data.invalid_uen.length > 0) {
                this.add_new_company = true
                this.company_info.uen_no = response.data.invalid_uen[0]
              } else {
                this.$bvModal
                  .msgBoxConfirm(
                    response.data.errors.join('\n'),
                    {
                      title: 'Errors detected',
                      size: 'sm',
                      okVariant: 'danger',
                      okTitle: 'Upload New File',
                      cancelTitle: 'Cancel',
                      cancelVariant: 'outline-secondary',
                      hideHeaderClose: false,
                      centered: true,
                    },
                  )
                  .then(value => {
                    if (value) {
                      this.showBulk()
                    }
                  })
              }
            } else {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'File Upload',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'success',
                  },
                },
                { timeout: this.$longestTimeout },
              )
              this.$refs.uploadCSV.value = ''
              this.file_upload = ''
              this.restoreCreds()
              this.$router.push('/applicant-tracking')
            }
          })
          .catch(error => {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'File Upload',
                  icon: 'AlertCircleIcon',
                  text: 'File upload failed. Try again later.',
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
            this.$refs.uploadCSV.value = ''
            this.file_upload = ''
            this.restoreCreds()
          })
      }
    },
    formChange() {
      // if (this.$refs.wizard.activeTabIndex === 1) {
      //   this.$ref.wizard.prevTab()
      // }
      const button = document.querySelector(
        '.wizard-footer-right > span > .wizard-btn',
      )

      const tab_index = this.$refs.wizard.activeTabIndex
    },
    goBack() {
      this.$router.go(-1)
    },

    searchText() {},

    handleInput: debounce(function (text) {
      this.employers = []
      this.getEmployers(text)
    }, 1000),
    showBulk() {
      this.$refs.uploadCSV.value = ''
      this.file_upload = ''
      this.show_bulk = true
      this.$refs.wizard.prevTab()
    },
    getEmployers(query) {
      this.employers = []
      this.restoreCreds()
      this.$http
        .get(`/api/company-search/${query}`)
        .then(response => {
          if (response.data.success) {
            for (const item of response.data.data) {
              this.employers.push({
                text: item.text,
                value: item.value,
              })
            }
          } else {
            this.event_exist = false
          }
        })
        .catch(error => {})
    },

    reset_form() {
      this.position = ''
      this.location = ''
      this.work_days = ''
      this.work_hours = ''
      this.work_location = {
        address: '',
        state: '',
        country: 'Singapore',
        postal_code: '',
      }
      this.description = ''

      this.select_tags = ''
      this.image_url = ''
      this.client_profile = ''(this.published_fields = {
        vacancies: true,
        salary: true,
        employer: false,
      }),
      (this.job_type = {
        parttime: false,
        freelance: false,
        fulltime: false,
        internship_traineeship: false,
        flexiwork: false,
        permanent: false,
        contract: false,
        temporary: false,
        prn: false,
      })
      this.optional = {
        team: '',
        union: '',
        destination: {
          siso: false,
          pmet: false,
          non_pmet: false,
          steadylah: false,
          persolkelly: false,
          main: false,
          adecco: false,
        },
        vacancies: '',
        poster_email: '',
        hiring_manager_email: '',
      }
      this.qualification_level = ''
      this.remote = false

      this.selected_employer = []
      this.requirements = ''
      this.min_salary = ''
      this.select_company = ''
      this.select_industry = [];
      (this.work_location = {
        address: '',
        state: '',
        country: 'Singapore',
        postal_code: '',
      }),
      (this.min_yoe = '')
      this.max_yoe = ''
      this.job_level = ''
      this.salary_currency = ''
      this.job_benefits = ''
    },

    restoreCreds() {
      const { token } = this.$store.state.auth.ActiveUser
      this.$http.defaults.headers.common.Authorization = `Token ${token}`
    },
    getHiringAgencies() {
      this.$http
        .get('/api/hiring-agencies')
        .then(response => {
          if (response.data.success) {
            this.all_hiring_agencies = response.data.hiring_agencies
          }
        })
        .catch(error => {})
    },
    getCredits() {
      this.$http
        .get('/api/credits')
        .then(response => {
          if (response.data.success) {
            this.credits = response.data.credits
          }
        })
        .catch(error => {
        })
    },

    extract_values() {
      const preferences = []
      let job_type = ''
      if (this.job_type.internship_traineeship) {
        preferences.push('internship_traineeship')
      }
      if (this.job_type.freelance) {
        preferences.push('freelance')
      }
      if (this.job_type.fulltime) {
        job_type = 'fulltime'
      }
      if (this.job_type.parttime) {
        job_type = 'parttime'
      }
      if (this.job_type.temporary) {
        preferences.push('temporary')
      }
      if (this.job_type.contract) {
        preferences.push('contract')
      }
      if (this.job_type.permanent) {
        preferences.push('permanent')
      }
      if (this.job_type.flexiwork) {
        preferences.push('flexiwork')
      }
      if (this.job_type.prn) {
        preferences.push('prn')
      }
      const target_channel = []

      for (const portal of this.all_portals) {
        if (this.optional.destination[portal]) {
          target_channel.push(portal)
        }
      }

      const all_published = []
      if (this.published_fields.employer) {
        all_published.push('employer')
      }

      if (this.published_fields.salary) {
        all_published.push('salary')
      }

      if (this.published_fields.vacancies) {
        all_published.push('vacancies')
      }

      if (this.published_fields.recruiter) {
        all_published.push('recruiter')
      }

      // let all_data = {
      //   position: this.position,
      //   description: this.description,
      //   image_url: this.image_url,
      //   job_type,
      //   preferences,
      //   requirements: this.requirements,
      //   work_days: this.work_days,
      //   work_hours: this.work_hours,
      //   min_salary: this.min_salary,
      //   salary: this.salary,
      //   published_fields: this.published_fields,
      //   min_yoe: this.min_yoe,
      //   max_yoe: this.max_yoe,
      //   work_location_type: this.work_location_type,
      //   job_level: this.job_level,
      //   salary_cycle: this.salary_cycle,
      //   salary_currency: this.salary_currency,
      //   job_benefits: this.job_benefits,
      //   work_location: this.work_location,
      //   qualification_level: this.qualification_level,
      //   remote: this.remote,
      //   published: all_published,
      //   is_agm: this.app_gurantee !== 'none',
      //   agm_type: this.app_gurantee,
      //   engaged_companies: this.engaged_companies,
      //   agm_details: {
      //     guaranteed_period: this.guaranteed_period,
      //     payment_terms: this.payment_terms,
      //     estimated_guaranteed: this.estimated_guaranteed,
      //   },
      //   vacancies: this.optional.vacancies,
      //   questions: this.optional.questions,
      //   post_channel: target_channel,
      //   poster_email: this.$store.state.auth.ActiveUser.email,
      //   hiring_manager_email: this.optional.hiring_manager_email,
      //   required_skillset: this.required_skillset,
      //   employer:
      //     this.select_industry.length > 0
      //       ? 'nil'
      //       : this.select_company.split('#')[0],
      //   uen_no:
      //     this.select_industry.length > 0
      //       ? 'nil'
      //       : this.select_company.split('#')[1],
      //   // send_where: this.send_where
      // }

      let all_data = {...this.formData}
      all_data['is_agm'] = this.app_gurantee !== 'none'
      all_data['agm_type'] = this.app_gurantee
      all_data['post_channel'] = target_channel
      all_data['agm_details'] = {
        guaranteed_period: this.guaranteed_period,
        payment_terms: this.payment_terms,
        estimated_guaranteed: this.estimated_guaranteed,
      }

      if (this.specialCompanyChecks('A')) {
        all_data.expiry = this.job_expiry
        all_data.client_profile = this.client_profile
      }

      return all_data
    },

    async downloadTemplate() {
      const company = this.$store.state.auth.ActiveUser.company_name
      const template = 'ems_template.xlsx'
      // const template = 'video.mp4'
      // if (company === 'e2i') {
      //   template = 'job_template.xlsx'
      // } else if (this.specialCompanyChecks('A')) {
      //   template = 'persolkelly_template.xlsx'
      // } else {
      //   template = 'others.xlsx'
      // }

      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: 'Downloading File',
            icon: 'AlertCircleIcon',
            text: 'Downloading template in progress.',
            variant: 'warning',
            progress: 0,
            progressId: 'downloadTemplate',
          },
        },
        { timeout: 9999999999 },
      )

      this.$http
        .get(`/api/media/${template}`, { 
          responseType: 'arraybuffer',
          onDownloadProgress: function(progressEvent) {
            const uploadPercentage = parseInt(Math.round(( progressEvent.loaded / progressEvent.total ) * 100 ))
            const parent = document.querySelector('#downloadTemplate')
            parent.querySelector('.progress-bar').style = `width: ${uploadPercentage}%;`
          }.bind(this),
        })
        .then(response => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]))
          const fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', template)
          document.body.appendChild(fileLink)

          fileLink.click()
        })
    },

    uploadJobs(input) {
      this.file_upload = input
      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: 'File Attachment',
            icon: 'AlertCircleIcon',
            text: 'File attached successfully.',
            variant: 'success',
          },
        },
        { timeout: this.$longestTimeout },
      )
      
      // if (input.target.files && input.target.files[0]) {
      //   const reader = new FileReader()
      //   reader.onload = e => {}
      //   const fd = new FormData()
      //   fd.append('file', input.target.files[0])
      //   this.$http
      //     .post('/api/upload-ems-jobs', fd)
      //     .then(response => {
      //       if (response.data.errors.length > 0) {
      //         this.popupActive = false
      //         if (response.data.invalid_uen.length > 0) {
      //           this.add_new_company = true
      //         } else {
      //           this.$bvModal
      //             .msgBoxConfirm(
      //               response.data.errors.join('\n'),
      //               {
      //                 title: 'Errors detected',
      //                 size: 'sm',
      //                 okVariant: 'danger',
      //                 okTitle: 'Upload New File',
      //                 cancelTitle: 'Cancel',
      //                 cancelVariant: 'outline-secondary',
      //                 hideHeaderClose: false,
      //                 centered: true,
      //               },
      //             )
      //             .then(value => {
      //               if (value) {
      //                 this.showBulk()
      //               }
      //             })
      //         }
      //       } else {
      //         this.$toast(
      //           {
      //             component: this.$toastContent,
      //             props: {
      //               title: 'File Upload',
      //               icon: 'AlertCircleIcon',
      //               text: response.data.message,
      //               variant: 'success',
      //             },
      //           },
      //           { timeout: this.$longestTimeout },
      //         )
      //         this.$refs.uploadCSV.value = ''
      //         this.file_upload = ''
      //         this.restoreCreds()
      //         this.$router.push('/applicant-tracking')
      //       }
      //     })
      //     .catch(error => {
      //       console.log(error)
      //       this.$toast(
      //         {
      //           component: this.$toastContent,
      //           props: {
      //             title: 'File Upload',
      //             icon: 'AlertCircleIcon',
      //             text: 'File upload failed. Try again later.',
      //             variant: 'danger',
      //           },
      //         },
      //         { timeout: this.$longestTimeout },
      //       )
      //       this.$refs.uploadCSV.value = ''
      //       this.file_upload = ''
      //     })
      // }
    },
    cancelUpload() {
      this.file_upload = ''
      this.$refs.uploadCSV.value = ''
      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: 'File Upload Status',
            icon: 'AlertCircleIcon',
            text: 'File upload cancelled.',
            variant: 'success',
          },
        },
        { timeout: this.$longestTimeout },
      )
    },

    specialCompanyChecks(group_type) {
      if (group_type == 'A') {
        const companies = [
          'DYNAMIC HUMAN CAPITAL PTE. LTD.',
          'PersolKelly',
          'FASTCO PTE. LTD.',
          'ELITEZ & ASSOCIATES PTE. LTD.',
          'ELITEZ PTE. LTD.',
          'ADECCO PERSONNEL PTE LTD',
        ]

        if (companies.includes(this.$store.state.auth.ActiveUser.company_name)) {
          return true
        }
      }

      return false
    },
    updateFormData(data) {
      this.formData = {...this.formData, ...data}
    },
    handleValidation() {
      if (this.$refs.wizard.activeTabIndex === 0) {
        this.showInterviewInput = false
        return true
      } else if (this.$refs.wizard.activeTabIndex === 1) {
        // this.showInterviewInput = !this.showInterviewInput
        if (this.showInterviewInput === false) {
          this.showInterviewInput = true
          return false
        }
        this.showInterviewInput = false
        return true
      }
      // this.$refs.wizard.nextTab()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style type="scss">

.vue-slider-process {
  background-color: #ff9f43 !important;
}

.vue-slider-dot-tooltip-text {
  border-color: #ff9f43 !important;
  background-color: #ff9f43 !important;
}
.vue-slider-dot-tooltip-inner {
  border-color: #ff9f43 !important;
  background-color: #ff9f43 !important;
}
</style>